.pageTitle {
    margin-bottom: 36px;
    &__textMain {
        font-family: Kanit;
        font-size: 24px;
        font-weight: 400;
        line-height: 35.88px;
        margin-bottom: 8px;
    }

    &__textSecondary {
        font-family: Kanit;
        font-size: 16px;
        font-weight: 300;
        line-height: 23.92px;        
    }
}

.pageWrapper {
    width: 100%;
}

.pageContentWrapper {
    padding: 24px;
    width: 100%;
   display: flex;
   flex-direction: column;
   gap: 24px;
}

.promptSettingsContainer {
    display: flex;
    gap: 72px;
    width: 100%;
}

.promptsListWrapper {
    width: 450px;
}

.promptsList {
    
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    &__link {
        display: flex;
        padding: 8px 0px 8px 16px;
        text-decoration: none;
        font-size: 16px;
        font-weight: 400;
        border-radius: 4px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        color: #191F2E;
        &_custom {
            padding: 8px 0px 8px 24px;
            gap: 4px;
        }
    }
}

.promptSetting {
    height: fit-content;
    width: 100%;
    background-color: rgba(224, 232, 249, 0.25);
    padding: 24px;
    border-radius: 4px;
    &__sectionTitle {
        font-family: Kanit;
        font-size: 16px;
        font-weight: 400;
        line-height: 23.92px;
        margin-top: 12px;
        margin-bottom: 6px;
    }
    &__textarea {
        border: 1px solid var(--neutral-light-gray);
        border-radius: 4px;
        width: 100%;
        height: auto;
        padding: 16px;
        font-family: Inter;
        font-size: 16px;
        caret-color: #3B2470;

              
        &::placeholder {
          font-family: Inter;
          font-size: 14px;
          font-weight: 300;
          letter-spacing: 1px;
        }
        &:focus {
          outline: none !important;
          border: 1px solid var(--colr-darkorchido);
            box-shadow: 0px 0px 2px 0px var(--colr-darkorchido);
        }
    }

    &__varablesWrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        gap: 12px
    }

    &__variableItem {
        border-radius: 4px;
        padding: 4px 8px;
        background-color: rgba(224, 232, 249, 1);
        font-family: Kanit;
        font-size: 16px;
        font-weight: 300;
        line-height: 23.92px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        &_checked {
            background-color: rgba(191, 232, 177, 1);
        }
    }

    &__checkboxesWrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 220px;
        margin-top: 32px;
        margin-bottom: 48px;
    }

    &__checkboxWrapper {
        width: 100%;
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: space-between;
    }

    &__checkBoxTitle {
        font-family: Kanit;
        font-size: 16px;
        font-weight: 400;
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__mainActions {
        display: flex;
        gap: 24px;
        align-items: center;
    }

    &__cancelButton {
        padding: 8px 16px;
        gap: 8px;
        width: 55px;
        height: 27px;
        font-family: 'Kanit';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: rgba(59, 36, 112, 1);  
    }

    &__deleteButton {
        padding: 16px 16px;
        gap: 8px;
        width: 100px;
        height: 27px;
        font-family: 'Kanit';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: rgba(59, 36, 112, 1); 
    }

    &__checkboxMesssageInviteWrapper {
        display: flex;
        gap: 24px;
        
    }

    &__checkBoxMessageInviteTitle {
        font-family: Kanit;
        font-size: 16px;
        font-weight: 400;
        line-height: 23.92px;
        
    }

    &__messsageInviteTitle {
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        margin-top: 36px;
        margin-bottom: 24px;
    }

}



.messageCounter {
    display: flex;
    font-family: Kanit;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;

    &__title {
        font-size: 16px;
        font-weight: 400;
    }
    &__count {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;       
    }

    &__commentsLeft {
        background-image: linear-gradient(
            145deg,
            hsl(231deg 95% 7%) 0%,
            hsl(226deg 72% 14%) 34%,
            hsl(238deg 50% 23%) 41%,
            hsl(252deg 49% 30%) 42%,
            hsl(279deg 55% 33%) 44%,
            hsl(316deg 100% 31%) 48%,
            hsl(335deg 100% 39%) 54%,
            hsl(0deg 69% 50%) 61%,
            hsl(15deg 74% 49%) 69%,
            hsl(18deg 74% 54%) 78%,
            hsl(20deg 75% 59%) 89%,
            hsl(22deg 76% 65%) 100%
        );
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        font-weight: 500;
    }

    &__commentsSum {
        color: black;
        
    }   
}

.setting {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    &__mainTitle {
        font-family: Kanit;
        font-size: 18px;
        font-weight: 400;
        line-height: 26.91px;
        margin-bottom: 24px;  
    }

    
    &__wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 48px;
        margin-bottom: 24px;
    }

    &__mainSetting {
        width: 450px;
    }

    &__title {
        font-family: Kanit;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #6F7D9B;
        margin-bottom: 12px;

    }
    &__footer {
        display: flex;
        justify-content: flex-end;
    }

    &__footerTitle {
        font-weight: 400;
        font-size: 16px;
        font-family: var(--font-family-kanit);   
    }
    &__parametr {        
        display: inline;
        margin: 0 10px;
        border: 1px solid black;
        padding: 0px 10px;
        border-radius: 10px;
        background-color: #3B2470;
        color: white;
        opacity: 0.6;
    }

    &__switchersWrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 2px;
    }

    &__switcherWrapper {
        width: 150px;
        display: flex;
        justify-content: flex-start;
        box-shadow: 1 0 2px 1px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        padding: 4px 10px;
    }
}

.checkbox {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    &__titleWrapper {
        display: flex;
        flex-direction: column;
    }
    &__title {
        font-weight: 300;
        font-size: 18px;
        font-family: var(--font-family-kanit);
    }
    &__subtitle {
        font-weight: 200;
        font-size: 14px;
        font-family: var(--font-family-kanit);
        opacity: 0.9;
    }
}

.accordion {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      border: 1px solid #E0E8F9;
      border-radius: 2px;
      width: 315px;
      padding: 6px 16px;
    }
  
    &__leadInfoContainer{
      display: flex;
      align-items: center;
      gap: 12px;
    }
  
    &__infoContainer {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  
    &__name {
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.36px;
      text-align: left;
      color: #3B2470;
    }
  
    &__additionalInfo{
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.52px;
      text-align: left;
      color: #191F2E;
    }
  }
