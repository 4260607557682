.userInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .firstLastNameText {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #3B2470;
        text-decoration: none;
    }
}

.profileWrapper {
    display: flex;
}

.profileImg {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    margin-right: 8px;
}



.tableHead {
    border: 0;
    width: 100%;

    &__row {
        max-height: '64px'
    }

    &__cell {
        font-family: 'Kanit';
        font-size: 16px;
        color: #191F2E;
        padding: 0;
    }

    &__cellContainer {
        padding: 0 12px;
        height: 64px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}
