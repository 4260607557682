.popup {
  background: #FFFFFF;
  border-radius: 2px;
  width: 500px;
  height: 550px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 12px;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #191F2E;
  }

  &__closeButton {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    overflow: hidden;
  }

  &__subTitle {
    margin-top: 16px;
    font-family: Kanit;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.92px;
  }
}

.promptContent {
  &__title {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px;
    margin-top: 8px;
    color: rgba(109, 48, 183, 1);
    font-family: Kanit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  &__prompts {
    width: 100%;
    height: 280px;
    overflow-y: scroll;
  }

  &__prompt {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__label {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    color: rgba(59, 36, 112, 1);
  }

  &__promptText {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    margin-left: 30px;

  }
}


.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__mainSwitcherContainer {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 2px;
    margin-bottom: 8px;
  }

  &__mainSwitcherTitle {
    font-family: Kanit;
    font-size: 16px;
    font-weight: 300;
    line-height: 23.92px;
    text-align: left;
    color: '#191F2E';
  }

  &__searchContainer {
    display: flex;
    gap: 6px;
    align-items: center;
  }

  &__itemsContainer {
    display: flex;
    flex-direction: column;
    gap: 6px;
    overflow-y: auto;
    height: 290px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    padding: 6px;
  }

  &__leadInfoContainer{
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__infoContainer {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__name {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
    color: #3B2470;
  }

  &__additionalInfo{
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: #191F2E;
  }
}

  

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  // padding-top: 33px;
  align-items: center;

  &__cancel {
    padding: 8px 16px;
    gap: 8px;
    width: 55px;
    height: 27px;
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #6F7D9B;
  }
}

