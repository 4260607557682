@mixin centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.botManagement {
  &__mainContainer {
    margin: 0 auto;
    width: 1100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 24px;
  }

  &__mainTitle {
    font-family: Kanit;
    font-size: 24px;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 24px;
    width: 100%;

    &_content {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 24px;
      padding: 24px;
      width: 100%;
    }

    &_workList {
      padding: 24px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
    }
    &_workListItem {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &_workListName {
      font-family: Kanit;
      font-size: 14px;
      width: 100px;
      flex-grow: 1;
    }

    &_selectUserContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &_selectUser {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40%;
      padding: 12px;
    }

    &_runParsing {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 20%;
      padding: 12px;
    }

    &_actionTitle {
      font-family: Kanit;
      font-size: 18px;
      margin-bottom: 12px;
    }

    &_linkInputContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 12px;
      width: 30%;
    }
  }

  &__botsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding: 24px;
  }

  &__botsLoadingContainer {
    height: 346px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__botsHeader {
    display: flex;
    gap: 24px;
    font-family: Kanit;
  }

  &__headerBlock {
    @include centered;
  }

  &__bots {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 12px;

    &_item {
      display: flex;
      gap: 24px;
    }


    &_name {
      font-family: Kanit;
      font-size: 14px;
      width: 120px;
      @include centered;
    }

    &_validCookie {
      width: 70px;
      @include centered;
    }

    &_isBusy {
      width: 70px;
      @include centered;
    }

    &_workType {
      font-family: Kanit;
      width: 200px;
      @include centered;
    }    
  }

  &__statistic {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 24px;
    width: 100%;

    &_list {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;
    }

    &_card {
      display: flex;
      gap: 24px;
      width: 100%;
      &_img {
        @include centered;
      }
      &_name {
        text-align: center;
        font-family: Kanit;
        font-size: 14px;
        width: 80px;
        @include centered;
      }

      &_workName {
        font-family: Kanit;
        font-size: 14px;
        width: 80px;
        @include centered;
      }

      &_counter {        
        font-family: Kanit;
        font-size: 14px;
        width: 80px;
        @include centered;
      }

      &_date {
        text-align: center;
        font-family: Kanit;
        font-size: 14px;
        width: 60px;
        @include centered;
      }

      &_boolean {
        width: 50px;
        @include centered;
      }

      &_error {
        font-family: Kanit;
        font-size: 14px;
        width: 250px;
        @include centered;
      }
    }

    &_charts {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 24px;
      flex-wrap: wrap;
      &_chart{
        display: flex;
        flex-direction: column;
        align-items: center;
        &_title{
          font-family: Kanit;
          font-size: 18px;
          font-weight: 300;
          color: var(--neutral-gray)
        }
      }
    }    
  }

  &__botsStatisticHeader {
    width: 100%;
    display: flex;
    gap: 24px;
    font-family: Kanit;
  }

  &__headerStatisticBlock {
    text-align: center;
    @include centered;
  }

  &__snLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 24px;
    width: 100%;

    &_list {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;
    }

    &_card {
      display: flex;
      align-items: center;
      gap: 24px;
      width: 100%;

      &_id {
        @include centered;
        font-family: Kanit;
        font-size: 14px;
        width: 20px;
      }

      &_dateInsert {
        text-align: center;
        font-family: Kanit;
        font-size: 14px;
        width: 200px;
        @include centered;
      }

      &_uploadingStep {
        font-family: Kanit;
        font-size: 14px;
        width: 150px;
        @include centered;
      }

      &_totalLeadsCount {
        font-family: Kanit;
        font-size: 14px;
        width: 50px;
        @include centered;
      }

      &_uploadingErrorMessage {
        font-family: Kanit;
        font-size: 14px;
        width: 300px;
        text-align: center;
        @include centered;
      }
    }
  }
}

