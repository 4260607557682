.popup {
  background: #FFFFFF;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 24px;
  gap: 24px;
  height: fit-content;

  &__invoiceSubscriptionEnd {
    width: 530px;
    
  }
  &__newSubscription {
    width: 530px;
  }
  &__ppgSubscriptionEnd {
    width: 660px;
  }
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__img {
    margin-top: 24px;
  }

  &__title {
    font-family: var(--font-family-kanit);
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    padding: 24px 32px 12px;
  }

  &_exTitle {
    width: 100%;
    font-family: var(--font-family-kanit);
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    color: var(--neutral-gray);
    margin-bottom: 24px;
  }

  &__subTitle {
    width: 100%;
    font-family: var(--font-family-kanit);
    font-size: 14px;
    font-weight: 300;
    &__ppgSubscriptionEnd {
      padding: 10px 24px;
    }
  }
}

.footer {
  margin-top: 12px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 6px; 
}

