@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.MuiButtonBase-root {
  text-transform: none;
}

body {
  height: 100vh;
  font-family: Kanit;
  margin: 0;
  line-height: normal;
}
body.popup-open {
  overflow: hidden;
}
:root {
  /* fonts */
  font-family: Kanit;
  --font-family-kanit: Kanit;
  --h4-body: Kanit;
  --h5-heading: Inter;

  /* font sizes */
  --h4-body-size: 16px;
  --h3-heading-size: 18px;
  --h2-heading-size: 24px;
  --h1-heading-size: 28px;
  --font-size-sm: 14px;
  --font-size-xs: 12px;
  --font-size-9xl: 28px;

  /* Colors */
  --neutral-white: #fff;
  --primary: #3B2470;
  --neutral-gray: #6f7d9b;
  --neutral-back: #191f2e;
  --accent: #db491f;
  --neutral-light-gray: #c7d1e5;
  --colr-darkorchido: #6d30b7;
  --secondary: #E0E8F9;

  /* Gaps */
  --gap-5xl: 24px;
  --gap-5xs: 8px;
  --gap-9xs: 4px;
  --gap-base: 16px;

  /* Paddings */
  --padding-base: 16px;
  --padding-5xs: 8px;
  --padding-5xl: 24px;
  --padding-xs: 12px;
  --padding-7xs: 6px;
  --padding-8xs: 5px;
  --padding-45xl: 64px;

  /*Margins*/
  --margin-base: 16px;
  
  /* border radiuses */
  --br-11xs: 2px;
  --br-9xs: 4px;
  --br-10xs: 3px;
  /*sidebar size*/
  --sidebar-width: 272px;
  --sidebar-width-narow: 88px;

  --header-height: 64px;
  /*filter leads size*/
  --filter-width: 350px;


  /* Custom scroll bar */
::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
::-webkit-scrollbar-track {
  background: #c7d1e5; 
}
::-webkit-scrollbar-thumb {
  background: #6f7d9b; 
}
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
}
