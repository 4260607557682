.pageContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  width: 100%;
}

.deleteBtnContainer {
  background-color: #FFFFFF;
  margin-right: auto;
  border-radius: 2px;
  height: 40px;
  width: 200px;
  display: flex;
  justify-content: flex-start;

  .deleteBtn {
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #191F2E;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #E0E8F9;
      padding: 8px 16px;
  }
}

.toolTipBody {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;
  gap: var(--gap-5xs);

  &__add_button {
    padding: 8px 16px;
    background: #3B2470;
    border-radius: 4px;
    box-shadow: none;
    font-family: var(--font-family-kanit);
    display: flex;
    gap: 8px;
  }
}
