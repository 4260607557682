.popup {
  background: #FFFFFF;
  border-radius: 2px;
  width: 600px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 24px;
}

.header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &__title {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #191F2E;
  }

  &__closeButton {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    overflow: hidden;
  }
}


.content {
  width: 100%;
  height: 400px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }

  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Kanit';
    font-weight: 300;
    margin-top: 24px;
    margin-bottom: 12px;
    font-size: 18px;
    &_bold {
      font-weight: 400;
      margin-left: 6px;
      margin-right: 2px;
    }
  }
}

  

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 33px;
  align-items: center;

  &__cancel {
    padding: 8px 16px;
    gap: 8px;
    width: 55px;
    height: 27px;
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #6F7D9B;
  }
}

