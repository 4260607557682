.toolTipBody {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
}