.usersStatistic {
  &__pageContainer {
    width: 100%;
    height: fit-content;
    padding-bottom: 24px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__mainTitle {
      font-family: Kanit;
      font-size: 32px;
      font-weight: 500;   
    }
    &__actions {
      display: flex;
      gap: 12px;
    }
  }

  &__generalStatisticList {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    &__card {
      flex-grow: 1;
      width: 290px;
      background-color: rgba(224, 232, 249, 0.4);
      padding: 20px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      // gap: 12px;
    }

    &__imgContainer {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: rgba(59, 36, 112, 1);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__cardTitle {
      font-family: Kanit;
      font-size: 18px;
      font-weight: 400;
      margin-top: 12px;
    }

    &__cardValue {
      margin-top: 8px;
      font-family: Kanit;
      font-size: 36px;
      font-weight: 400;
    }

    &__cardDescriptiobn {
      font-family: Kanit;
      font-size: 16px;
      font-weight: 400;
      color: rgba(179, 187, 202, 1);
    }
  }

  &__diagramsFeaturesContainer {
    display: flex;
    gap: 24px;
    margin-top: 24px;
  }

  &__diagramsContainer {
    display: flex;
    flex-grow: 1;    
  }

  &__diagramsResponsiveContainer {
    height: 360px;
    width: 100%;
  }

  &__emptyDiagrams {
    font-family: Kanit;
    height: 100%;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.4;
  }

  &__diagramsLoadingContainer {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__diagrams {
    flex-grow: 1;
    border-top: 1px solid rgba(224, 232, 249, 1);
    border-bottom: 1px solid rgba(224, 232, 249, 1);
    border-left: 1px solid rgba(224, 232, 249, 1);
    border-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      &__title {
        font-family: Kanit;
        font-size: 24px;
        font-weight: 400;
      }
      &__actions {
        display: flex;
        gap: 4px;
      }
    }
  }

  &__campaignList {
    width: 240px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border: 1px solid rgba(224, 232, 249, 1);
    border-radius: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 12px 24px;
  }

  &____campaignList::-webkit-scrollbar {
    display: none;
  }

  &__campaignListItem {
    width: 200px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
 
    &::before {
      content: '';
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }


  &__features {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__reactions {
      background-color: rgba(59, 36, 112, 1);
      width: 360px;
      height: 230px;
      padding: 24px;
      overflow: hidden;
      position: relative;
      border-radius: 8px;

      &__titleContainer {
        display: flex;
        align-items: center;
        gap: 12px;
        height: 50px;
      }

      &__title {
        position: relative;
        font-family: Kanit;
        font-size: 24px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        z-index: 2;
      }

      &__linkContainer {
        position: relative;
        z-index: 2;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(109, 48, 183, 1);
        margin-left: 8px;
        transition: opacity 0.2s;
        cursor: pointer;
        &:hover {
          opacity: 0.6;
        }
      }

      &__value {
        position: relative;
        font-family: Kanit;
        font-size: 50px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        margin-top: 12px;
        z-index: 2;
      }

      &__description {
        position: relative;
        font-family: Kanit;
        font-size: 16px;
        font-weight: 400;
        color: rgba(187, 194, 209, 1);
        z-index: 2;
        width: 230px;
      }

      &__bg {
        position: absolute;
        width: 300px;
        height: 420px;
        top: -60px;
        left: 60.46px;
        z-index: 1;
      }
    }



    &__connections {
      position: relative;
      width: 360px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid rgba(224, 232, 249, 1);
      border-radius: 8px;
      padding: 24px;
      &__totalContainer {
        position:absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      &__totalTitle {
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        color: rgba(111, 125, 155, 1);
      }

      &__totalCount {
        font-family: Inter;
        font-size: 40px;
        font-weight: 700;
      }
    }
  }
}


.toolTipBody {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
}

