.pageContainerWithFilters {
  width: 100%;
  display: flex;
  gap: 24px;
}

.pageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;  
  gap: var(--gap-base);
}

.pageContainerWidth {
  width: 100%;
}

.pageContainerWidthFilterOpen {
  width: calc(100% - var(--filter-width));
}

.leadsFilter {
  width: var(--filter-width);
  height: calc(100vh - 60px);
  margin-right: -64px;
  margin-top: -24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F2F2F2;
    padding: 8px 12px;
  }

  &__closeBlock {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  &__closeButton {
    padding: 0;
  }

  &__counter {
    font-size: 16px;
  }

  &__clearAllButton {
    font-family: Kanit;
    font-size: 18px;
  }

  &__filtersContainer {
    padding: 18px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__footer {
    display: flex;
    gap: 24px;
    padding: 12px 18px;
  }

  &__cancelButton {
    font-family: Kanit;
    font-size: 16px;
    border: 1px solid #C7D1E5;
    border-radius: 1px;
  }
}

.toolTipBody {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
}

.actionsContainerMain {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.actionsContainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  gap: var(--gap-5xs);
}

.filterButton {
  font-size: 16px;
  font-weight: 300;
  color: #191F2E;
  background: #fff;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  box-shadow: none;
  font-family: var(--font-family-kanit);
  border-radius: 1px;
  border: 1px solid rgba(0, 0, 0, 0.247);
}

.exportButton {
  font-size: 16px;
  font-weight: 300;
  color: #191F2E;
  background: #E0E8F9;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  box-shadow: none;
  font-family: var(--font-family-kanit);
  border-radius: 1px;
}

.bulkButtonContainer {
  background-color: #E0E8F9;
  display: flex;
  align-items: center;
  gap: 18px;
  transition: width 0.3s ease;
  overflow: hidden;
  height: 48px;
  border-radius: 2px;
}

.selectedIndicator {
  display: flex;
  gap: 18px;
  align-items: center;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #191F2E;
}

.selectedBtnContainer {
  background-color: #FFFFFF;
  border-radius: 2px;
  height: 40px;
  display: flex;
  justify-content: flex-start;

  .selectAllBtn {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #191F2E;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #E0E8F9;
    padding: 8px;
    border-radius: 0;
  }
}


