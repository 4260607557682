.popupBody {


  background: #FFFFFF;
  border-radius: 2px;
  width: 582px;
  height: 437px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 24px;

  .header {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .title {
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      color: #191F2E;
    }

    .closeButton {
      position: relative;
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      overflow: hidden;
    }
  }

  .userInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: 550px;
    height: 48px;
    background: #FFFFFF;
    flex: none;
    align-self: stretch;
    flex-grow: 0;

    .profileImg {
      width: 40px;
      height: 40px;
      border-radius: 50px;
      margin-right: 8px;
    }
  }

  .messageToLead {
    width: 100%;
    height: 24px;
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #191F2E;

    .buttonGroup {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      padding-top: 33px;
      align-items: center;

      .send {
        padding: 8px 16px;
        gap: 8px;
        height: 40px;
        background: #3B2470;
        border-radius: 2px;
        color: #FFFFFF;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
      }

      .cancel {
        padding: 8px 16px;
        gap: 8px;
        width: 55px;
        height: 27px;
        font-family: 'Kanit';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #6F7D9B;
      }
    }
  }
}