.pagesTabs {
  display: flex;
  flex-direction: column;
  width: 100%;
  &__tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &__tab {
    min-width: 130px;
    max-width: 250px;
    text-decoration: none;
    font-size: 18px;
    color: var(--neutral-back);
    padding: 6px 25px 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    &_disabled {
      cursor: pointer;;
      color: var(--neutral-gray);
    }
  }
}
.toolTipBody {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  display: flex;
  align-items: flex-end;
}

.activeLink {
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px; /* Adjust as needed */
    border-radius: 1px;
    background: radial-gradient(50% 50% at 50% 50%,
        #010624,
        #492b83 30.21%,
        #d83c14 70.79%,
        #e99360);
    height: 2px;
    flex-shrink: 0;
  }
}

.deleteButton {
  padding: 4px 4px;
  border-radius: 4px;
  min-width: 20px;
  height: 20px;
  position: absolute;
  right: -10px;
  top: -2px;
}