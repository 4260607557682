.header {
    background-color: var(--neutral-white);
    display: flex;
    flex-direction: column;
    font-size: var(--h3-heading-size);
    color: var(--neutral-gray);
    font-family: var(--h4-body);
    // margin-bottom: 24px;
}

.header1 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 64px;
    padding: var(--padding-base) var(--padding-5xl);
    gap: var(--gap-5xl);
    position: relative;
}

.logoWrapper {
    position: absolute;
    top: 10px;
    left: 24px;
}

.divider {
    position: relative;
    background-color: var(--neutral-light-gray);
    height: 1px;
    flex-shrink: 0;
    margin-top: -1px;
}

.notifications {
    &__item {
        width: 500px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        padding: 12px 16px;
    }
    
    &__userInfo {
        font-family: 'Kanit';
        display: flex;
        align-items: center;
        gap: 12px;
    }

    &__infoContainer {
        display: flex;
        flex-direction: column;
    }

    &__name {
        font-size: 16px;
    }

    &__reactionText {
        font-size: 16px;
        font-weight: 300;
    }

    &__timeWithIcon {
        display: flex;
        gap: 12px;
    }

    &__time{
        font-family: 'Kanit';
        color: #6F7D9B;
    }
}
