.noLeads {
  width: 100%;
  flex-grow: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.noLeadsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}