.app {
  display: flex;

  &__sidebar {
    position: sticky;
    top:0;
    background-color: var(--neutral-back);    
    height: 100vh;
    padding: 16px;
    overflow-y: auto;
    flex-shrink: 0;
  };

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: var(--h3-heading-size);
    color: var(--neutral-back);
    font-family: var(--h4-body);
    overflow-x: hidden;
  }

  &__page {
    padding: 0 24px;
    padding-top: 24px;
    position: relative;
  }
}
