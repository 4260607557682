.addLead {
  flex: 1;
  position: relative;
}

.closeBlack24dp1Icon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
  color: #FFFFFF;
}

.closePopUpBtn {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
  color: #191F2E;
}

.addLeadParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}

.div {
  align-self: stretch;
  position: relative;
  display: inline-block;
  width: 7px;
  flex-shrink: 0;
}

.linkToLeads {
  height: 24px;
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;  
  color: #191F2E;
}

.titleRequired {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.input {
  flex-direction: row;
  align-items: center;
  padding: 6.5px 8px;
  gap: 8px;
  height: 37px;
  background: #FFFFFF;
  border: 1px solid #6F7D9B;
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.command {
  width: 550px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  font-size: var(--h4-body-size);
  color: var(--accent);
}

.addBlack24dp1Icon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}

.closeBtn {
  position: relative;
}

.button {
  border-radius: var(--br-11xs);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-base);
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}

.closeBtn {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #6F7D9B;
}

.button1,
.buttons1 {
  display: flex;
  flex-direction: row;
}

.button1 {
  border-radius: var(--br-11xs);
  background-color: var(--primary);
  padding: var(--padding-5xs) var(--padding-base);
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}

.buttons1 {
  flex: 1;
  align-items: flex-start;
  justify-content: flex-end;
  font-size: var(--h4-body-size);
  color: var(--neutral-white);
}

.buttons,
.popupAddLead {
  display: flex;
  justify-content: flex-start;
}

.buttons {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-5xs);
  font-size: var(--h3-heading-size);
  color: var(--neutral-gray);
}

.popupAddLead {
  position: relative;
  border-radius: var(--br-11xs);
  background-color: var(--neutral-white);
  flex-direction: column;
  padding: var(--padding-base);
  box-sizing: border-box;
  align-items: flex-start;
  gap: var(--gap-5xl);
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  text-align: left;
  font-size: var(--h2-heading-size);
  color: var(--neutral-back);
  font-family: var(--h4-body);
}

.addLeadBtn {
  padding: 0;
  color: #FFFFFF;
}