.popup {
  background: #FFFFFF;
  border-radius: 2px;
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 24px;
}

.header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &__title {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #191F2E;
  }

  &__closeButton {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    overflow: hidden;
  }
}


.content {
  width: 100%;
  &__download {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
  }

  &__downloadText {
    font-family: 'Kanit';
    font-size: 14px;
    font-weight: 300;
  }

  &__downLoadButton {
    display: flex;
    gap: 6px;
  }

  &__downLoadButtonText {
    font-family: 'Kanit';
    font-size: 14px;
    font-weight: 400;
    color: #3B2470;
  }

  &__upload {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  &__uploadButton {
    color: white;
    font-weight: 300;
    font-size: 14px;
    cursor: pointer;
    width: 120px;
    height: 30px;
    background: #3B2470;
    border-radius: 1px;
    box-shadow: none;
    font-family: var(--font-family-kanit);
    &:hover {
      background: #3B2470;
    }

    &:disabled {
      background: #3B2470;
      color: white;
      opacity: 0.6;
    }
  }

  &__note {
    font-size: 12px;
    font-family: Kanit;
    color: var(--neutral-light-gra);
    opacity: 0.7;
    margin-bottom: 24px;

  }
}

  

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__action {
    color: white;
    font-weight: 300;
    font-size: 16px;
    cursor: pointer;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    width: 143px;
    height: 40px;
    background: #3B2470;
    border-radius: 1px;
    box-shadow: none;
    font-family: var(--font-family-kanit);
    &:hover {
      background: #3B2470;
    }

    &:disabled {
      background: #3B2470;
      color: white;
      opacity: 0.6;
    }
  }

  &__cancel {
    padding: 8px 16px;
    gap: 8px;
    width: 55px;
    height: 27px;
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #6F7D9B;
  }
}

.contentSuccess {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__img {
    margin-top: 24px;
  }

  &__title {
    font-family: var(--font-family-kanit);
    font-size: 22px;
    font-weight: 400;
    text-align: center;
    padding: 24px 32px;
  }

  &__unvalidLinks {
    height: 200px;
    width: 100%;
    overflow-y: scroll;
  }

  &__link {
    font-family: Kanit;
    display: flex;
    padding: 2px;
    gap: 6px;
  }

  &__linkContent {
    display: flex;
    gap: 4px;
  }

  &__property {
    color: var(--neutral-back)
  }

  &__defenition {
    color: var(--neutral-gray)
  }

  &__subTitle {
    font-family: var(--font-family-kanit);
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    padding: 12px 32px;
    margin-bottom: 24px;
  }
}

