.pageContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: 0 auto;
  padding: 24px;
  gap: var(--gap-base);
}

.actionsContainerMain {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  width: 100%;
}

.profileWrapper {
  display: flex;
}

.profileImg {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  margin-right: 8px;
}

.userInfo {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .firstLastNameText {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #3B2470;
  }

  .titleText {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #191F2E;
      overflow: 'hidden';
      text-overflow: 'ellipsis';
      white-space: 'nowrap';
  }
}

.monitoringProxies {
  &__mainContainer {
    display: flex;
    justify-content: space-between;
  }
  &__pageTypesContainer {
    display: flex;
    gap: 12px;
  }
  &__actionsContainer {
    display: flex;
  }
  &__tableTitle {
    position: relative;
    cursor: pointer;
    font-family: Kanit;
    font-size: 18px;
    margin-bottom: 12px;
    padding: 4px 12px;
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out;
    transition: color 0.2s linear;
    
    &:hover {
      color: rgba(199, 209, 229, 1);
    }

    &_active {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -12px; /* Adjust as needed */
        border-radius: 1px;
        background: radial-gradient(50% 50% at 50% 50%,
            #010624,
            #492b83 30.21%,
            #d83c14 70.79%,
            #e99360);
        height: 2px;
        flex-shrink: 0;
      // background: rgba(199, 209, 229, 1);
      color: rgba(25, 31, 46, 1);
      }
      &:hover {
        // background: rgba(199, 209, 229, 1);
        color: rgba(25, 31, 46, 1);
      }
    }
  }
}
