.accountDetails {
  &__pageContainerLoading {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__pageContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin: 0 auto;
    gap: var(--gap-base);
  }

  &__AccountDetailsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &__title {
      font-family: Kanit;
      font-size: 24px;
      font-weight: 400;
      color: rgba(25, 31, 46, 1);
    }

    &__contactButton {
      text-decoration: none;
      display: flex;
      align-items: center;
      height: 48px;
      padding: 12px 16px 12px 16px;
      gap: 10px;
      border-radius: 2px;
      background-color: rgba(224, 232, 249, 1);
      color: rgba(25, 31, 46, 1);
      transition: box-shadow 0.3s ease;
      box-shadow: none;
      font-family: Kanit;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      &:hover {
        background-color: rgba(224, 232, 249, 1);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2), 0px 6px 20px rgba(0, 0, 0, 0.19);
      }
    }
  }

  &__userInfoSubscriptionContainer {
    width: 100%;
    padding: 12px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 1px solid rgba(224, 232, 249, 1);
    border-radius: 8px;
    gap: 12px;   
  }

  &__userInfoContainer {
    display: flex;
    gap: 24px;

    &__avatar {
      width: 110px;
      height: 110px;
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

    }

    &__name {
      font-family: Kanit;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 4px;
    }

    &__title {
      font-family: Kanit;
      font-size: 18px;
      font-weight: 300;
      color: rgba(111, 125, 155, 1);
      margin-bottom: 4px;
      width: 400px;
    }

    &__email {
      font-family: Kanit;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 24px;
    }
  }

  &__subscriptionSummary {
    height: fit-content;
    width: 400px;
    &__noSubscription {
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: rgba(111, 125, 155, 1);
    }
    &__title {
      font-family: Kanit;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 12px;
    }

    &__infoContainer {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &__info {
      display: flex;
      justify-content: space-between;
      
      align-items: center;
      &__name {
        font-family: Kanit;
        font-size: 16px;
        font-weight: 400;
        color: rgba(111, 125, 155, 1);
      }
      &__definition {
        width: 200px;
        font-family: Kanit;
        font-size: 16px;
        font-weight: 400;
        
        &__status {
          width: 200px;
          height: 30px;
          font-family: Kanit;
          font-size: 16px;
          font-weight: 400;
          display: flex;
          align-items: center;
          gap: 12px;
        }
      }
    }

    &__actions {
      width: 100%;
      margin-top: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    &__actionsSubscription {
      display: flex;
      gap: 12px;
    }
  }

  &__billingHistoryContainer {
    width: 100%;
    &__title {
      font-family: Kanit;
      font-size: 24px;
      font-weight: 400;
    }
  }
}

.toolTipBody {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
}

