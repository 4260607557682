.actionsWrapper {
  display: flex;
  flex-direction: column;
}
.actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  margin-top: 16px;
  color: var(--neutral-gray);

  &__autocompleteWrapper {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  &__keywordWrapper {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  &__keywordInputWrapper {
    width: 300px;
  }

  &__selectLastDaysWrapper {
    width: 210px;
  }

  &__actionButtonWrapper {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 10px;
    justify-self: flex-end;
    margin-top: 16px;
  }

  &__inputTitle {
    margin-bottom: 8px;
  }

  &__clearAllFilters {
    font-family: Kanit;
    font-size: 16px;
    font-weight: 400;
    color: var(--primary);
    padding: 8px 12px;
    height: 42px;
  }

  &__runAutomation {
    background-color: #E0E8F9;
    color: #3B2470;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    font-family: Kanit;
    box-shadow: none;
    border-radius: 1px;
    height: 42px;

    &:hover {
      background-color: #E0E8F9;
    }
  }
  
  &__selectExcludeLeadsWrapper {
    margin-top: 24px;
    margin-bottom: 18px;
    display: flex;
    gap: 18px;
    align-items: center;
    flex-wrap: wrap;
    height: 40px;
  }

  &__toggler {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    
    &_title {
      font-family: Kanit;
      font-size: 16px;
      color: #191F2E;
    }
  }
}

.postsWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-top: 24px;
  color: var(--primary);
}

.post {
  display: flex;
  gap: 16px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid var(--neutral-light-gray);
  padding: 16px;
  position: relative;

  &__contentName {
    font-family: Kanit;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: var(--neutral-back);
  }
  &__contentAddInfo {
    width: 90%;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: var(--neutral-back);
    margin-top: 8px;
  }
  &__contentDate {
    display: flex;
    gap: 6px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: var(--neutral-light-gray);
    margin-top: 6px;
  }
  &__contentText {
    font-family: Kanit;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: var(--primary);
    margin-top: 24px;
    margin-bottom: 16px;
  }
  
  &__delete{
    position: absolute;
    right: 16px;
    background-color: #F2F2F2;
    min-width: 32px;
  }

  &__actions {
    &_accordion {
      box-shadow: none;
      padding: 0;
      cursor: default;
    }
    &_btns {
      width: 100%;
      display: flex;
      gap: 8px
    }
    &_link {
      text-decoration: none;
    }
    &_view {
      height: 32px;
      padding: 4px 8px;
      gap: 4px;
      border-radius: 4;
      display: flex;
      align-items: center;
      font-family: Kanit;
      font-size: 16px;
      font-weight: 400;
      background-color: #D9ECF5;
      color: #007EBB;
    }

    &_comment {
      height: 32px;
      font-family: Kanit;
      font-size: 16px;
      color: var(--neutral-back);
      background-color: var(--secondary);
      &_clicked {
        border: 2px solid var(--primary)
      }
    }
  }

  &__comment {
    display: flex;
    gap: 16px;
    width: 100%;
    margin-top: 16px;

    &_textarea {
      border: 1px solid var(--neutral-light-gray);
      border-radius: 4px;
      width: 100%;
      height: auto;
      padding: 16px;
      font-family: Inter;
      font-size: 16px;
      
      &::placeholder {
        font-family: Inter;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 1px;
      }
      &:focus {
        outline: none !important;
        border: 1px solid var(--colr-darkorchido);
        box-shadow: 0px 0px 2px 0px var(--colr-darkorchido);
      }
    }

    &_actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap-reverse;
      gap: 10px;

      &_info {
        flex-grow: 1;
        font-family: Kanit;
        font-size: 16px;
        font-weight: 300;
        color: var(--neutral-gray);
      }

      &_wrapper {
        display: flex;
        align-items: center;
        gap: 2px;
     }

     &_loaderWrapper {
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
     }
    }
  }
}

.toolTipBody {
  font-family: 'Kanit';
  font-style: normal;
  padding: 5px;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  display: flex;
  align-items: flex-end;
}
