.tableLoader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background: linear-gradient(45deg, #6d30b707, #e9926000);
  backdrop-filter: blur(2px);

  &__progress_top {
      position: absolute;
      top: 0;
      width: 99.8%;    
  }
}