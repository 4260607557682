.mainContainer {
    display: flex;
    justify-content: space-between;

    .leftSide {
        width: 50%;
        background-color: #191F2E;

        .contentWrapper {
            height: 100%;
            padding: 40px 90px 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
        }

        .logoSection {
            //padding-top: 80px;
            display: flex;
            align-items: flex-end;
            flex-direction: row;
            justify-content: center;

            .logoPadding {
                padding-right: 11px;
            }
        }

        .headerSection {
            font-weight: 400;
            font-size: 28px;
            line-height: 42px;
            text-align: center;
            color: #FFFFFF;
            padding-top: 33px;
        }

        .additionalInfo {
            font-family: 'Kanit';
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 27px;
            text-align: center;
            color: #FFFFFF;
            padding-top: 33px;
        }

        .mainHeaderInformation {
            font-family: 'Kanit';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: #FFFFFF;
            //padding-left: 14%;

            .mainListInformation {
                list-style-image: url('../../../../public/ellipse.svg');
                font-family: 'Kanit';
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 27px;
                color: #FFFFFF;

                p {
                    padding-left: 40px;
                }

                li {
                    padding-bottom: 10px;
                }
            }
        }

        .actionBtns {
            display: flex;
            height: 200px;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;

            .labelWithInputsWrapper {
                display: flex;
                flex-direction: column;

                .inputLabels {
                    font-family: 'Kanit';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #FFFFFF;
                    padding-bottom: 5px;
                }
            }

            .signUp {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-end;
                padding: 8px 16px;
                gap: 8px;
                background: #6D30B7;
                border-radius: 2px;
                width: 400px;
            }

            .labelLogin {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #FFFFFF;

                a {
                    color: #FFB7A3;
                }

                a:active {
                    color: #FFB7A3;
                }
            }

            .labelRestore {
                display: flex;
                width: 400px;
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #FFFFFF;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-end;
                align-items: center;

                .restoteBtn {
                    font-family: 'Kanit';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    color: #E6D9F8;
                    text-decoration: underline;
                }
            }
        }

        .footerInfo {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #FFFFFF;
        }

    }

    .rightSide {
        width: 50%;

        img {
            width: 100%;
            height: 100vh;
            position: sticky;
            top: 0;
            filter: brightness(50%);
        }
    }
}

input {
    caret-color: white;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: #FFFFFF;
    -webkit-box-shadow: 0 0 0px 0px #191F2E inset;
    transition: background-color 5000s ease-in-out 0s;
}