.portalPopupOverlay {
  display: flex;
  flex-direction: column;
  /* position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; */
  align-items: center;
  justify-content: center;
  z-index: 100;
}
