.sidebar {
    display: flex;
    flex-direction: column;
    font-family: var(--font-family-kanit);
    font-size: var(--h4-body-size);
    font-weight: 400;
    color: var(--neutral-white);

    &__logoWrapper {
        padding: var(--padding-base) 0 var(--padding-base) 0;
    }

    &__profile {
        display: flex;
        flex-direction: column;
        margin-top: 12px;

        &_firstLastName {
            margin-top: 8px;
        }

        &_organizationName {
            margin-top: 4px;
            font-size: 12px;
            font-family: var(--h5-heading);
        }

        &_organizationRole {
            margin-top: 4px;
            font-size: 12px;
            font-weight: 500;
            color: var(--neutral-light-gray);
        }
    }

    &__menu {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 2px;

        &_menuItem {
            display: flex;
            padding: 8px 0px 8px 8px;
            border-radius: 4px;
            text-decoration: none;
            color: var(--neutral-white);
            font-size: 16px;
            font-style: normal;
            font-weight: 300;            
        }
        &_text {
            margin-left: 8px;
            font-weight: 300;
        }
    }

    &__cookieBlock {
        border-radius: 4px;
        margin-top:12px;
        padding: 8px 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        font-size: 14px;

        &_info {
            font-weight: 300;
        }
        &_link {
            font-weight: 300;
            color: var(--neutral-white);
            margin-left: 3px;
        }
    }

    &__statisticBlockWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 12px;

        &_replyInfoWrapper {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 8px 12px;
            background: var(--primary);
            border-radius: 2px;
            gap: 16px;
        }

        &_statisticNumber {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        &_numberText {
            font-size: 24px;
            &_pending {
                color: #FBE7B1;
                font-size: 24px;
            }
        }

        &_iconAndTextWrapper {
            display: flex;
            align-items: center;
            gap: 4px;
            color: var(--neutral-light-gray);
            font-size: 14px;
        }
    }
    &__frameParent {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 8px 12px;
        background: var(--primary);
        border-radius: 2px;
        margin-top: 12px;

        &_numberText {
            font-size: 22px;
        }
        &_visibilityParent {
            display: flex;
            gap: 8px;
            color: var(--neutral-light-gray);
            font-size: 14px;
        }
    }

    &__upgradePlanButton {
        margin-top: 12px;
        height: 48px;
        padding: 12px 16px 12px 16px;
        gap: 10px;
        border-radius: 4px;
        background-color: rgba(253, 243, 216, 1);
        color: rgba(59, 36, 112, 1);
        transition: box-shadow 0.3s ease;
        box-shadow: none;
        font-family: Kanit;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        &:hover {
            background-color: rgba(253, 243, 216, 1);
          box-shadow: 0px 4px 8px rgba(253, 243, 216, 0.2), 0px 6px 20px rgba(253, 243, 216, 0.19);
        }
    }
}

.sidebarNarrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-family-kanit);
    font-size: var(--h4-body-size);
    font-weight: 400;
    color: var(--neutral-white);

    &__logoWrapper {
        margin-top: 24px;
    }
   
    &__logoIcon {
        padding: 8px;
    }

    &__menu {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    position: relative;

        &_menuItem {
            display: flex;
            padding: 8px;
            border-radius: 4px;
            text-decoration: none;
            color: var(--neutral-white);
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
        }
    }
}

.buttonWide {
    position: absolute;
    align-self: flex-end;
    width: fit-content;
    background-color: #E0E8F9;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 50%;
    border: 2px solid #191F2E;
    cursor: pointer;
}

.buttonNarrow {
    background-color: #E0E8F9;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 50%;
    border: 2px solid #191F2E;
    cursor: pointer;   
}
