.popup {
  background: #FFFFFF;
  border-radius: 2px;
  width: 582px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 24px;
}

.header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &__title {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #191F2E;
  }

  &__closeButton {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    overflow: hidden;
  }
}

.profileWrapper {
  display: flex;
}

.userInfo {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .firstLastNameText {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #3B2470;
  }

  .titleText {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #191F2E;
      overflow: 'hidden';
      text-overflow: 'ellipsis';
      white-space: 'nowrap';
  }
}

.profileImg {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  margin-right: 8px;
}


.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  &__settings {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__setting {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__toggler {
      margin-top: 12px;
      justify-content: flex-start;
      gap: 12px;
      height: 32px;
    }
  }

  &__settingTitle {
    font-family: Kanit;
  }

  &__subscription {
    height: 0;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    gap: 12px;
    transition: height 0.3s ease;
    &__open {
      height: 100px;
    }
  }
}



  

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 33px;
  align-items: center;

  &__cancel {
    padding: 8px 16px;
    gap: 8px;
    width: 55px;
    height: 27px;
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #6F7D9B;
  }
}


.trialDateBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
}

.trialDateBox .content__settingTitle {
  font-family: 'Kanit';
  font-size: 16px;
  color: #191F2E;
  white-space: nowrap;
}

.trialDateBox .datePickerWrapper {
  display: flex;
  justify-content: space-between;
  width: 400px;
}

.trialDateBox .datePickerWrapper .react-datepicker-wrapper {
  width: 100%;
}

.trialDateBox .react-datepicker__input-container input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.trialDateBox .clearButton {
  background-color: transparent;
  border: none;
  color: #6F7D9B;
}