.userInfo {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .firstLastNameText {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #3B2470;
    }

    .titleText {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #191F2E;
        overflow: 'hidden';
        text-overflow: 'ellipsis';
        white-space: 'nowrap';
    }
}

.profileWrapper {
    display: flex;
}

.profileImg {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    margin-right: 8px;
}

.tableLoader {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background: linear-gradient(45deg, #6d30b707, #e9926000);
    backdrop-filter: blur(2px);

    &__progress_top {
        position: absolute;
        top: 0;
        width: 99.8%;    
    }
}
