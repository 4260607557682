.deleteBtnContainer {
  background-color: #FFFFFF;
  border-radius: 2px;
  height: 40px;
  display: flex;
  justify-content: flex-start;

  .deleteBtn {
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      color: #191F2E;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #E0E8F9;
      padding: 8px 16px;
      border-radius: 0;
  }
}