.messageCounter {
  display: flex;
  align-items: center;
  gap: 8px;

  &__title {
    color: '#191F2E';
    font-family: Kanit;
    font-weight: 300;
    font-size: 16px;
  }

  &__commentsLeft {
      background-image: linear-gradient(
          145deg,
          hsl(231deg 95% 7%) 0%,
          hsl(226deg 72% 14%) 34%,
          hsl(238deg 50% 23%) 41%,
          hsl(252deg 49% 30%) 42%,
          hsl(279deg 55% 33%) 44%,
          hsl(316deg 100% 31%) 48%,
          hsl(335deg 100% 39%) 54%,
          hsl(0deg 69% 50%) 61%,
          hsl(15deg 74% 49%) 69%,
          hsl(18deg 74% 54%) 78%,
          hsl(20deg 75% 59%) 89%,
          hsl(22deg 76% 65%) 100%
      );
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-size: 18px;
  }  
}